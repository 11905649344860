const Entorno = "LIVE"; // 'LIVE'  para pro | 'QUA' para uat-qua
const PuertoEntorno = {
    QUA: "https://opentext.quercus.com.es:8443",
    LIVE: "https://opentext.quercus.com.es:443",
    JDEV: "http://localhost:8080",
};
export const APIs = {
    Login: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/conexionAdmin",
    ChangePassword: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/cambio",
    PersonalData: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/datospersonales?dni=",
    certificados: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/certificado/list?dni=",
    DescargaDocumentos: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/document/downloadContent?documentId=",
    nominas: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/nomina/list?dni=",
    AddressData: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/datosdomicilio?dni=",
    ContactData: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/datoscontacto?dni=",
    FamilyData: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/datosfamilia?dni=",
    PasswordRecovery: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/recuperacion?dni=",
    News: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/pancartas",
    holidaysRequest: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/ausencias/crear",
    getHolidays: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/ausencias/solicitudes",
    getAbsencesRepresentative: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/ausencias/solicitudesRepresentante",
    getDaysData: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/ausencias/vacaciones",
    rejectAbsence: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/ausencias/rechazar",
    approveAbsence: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/ausencias/aceptar",
    cancelAbsence: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/ausencias/cancelar",
    getSections: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/getSubsections",
    getSection: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/getSection",
    getSectionsAdmin: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/getSubsectionsAdmin",
    getMainSections: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/getSubsectionsMain",
    getMainSectionsAdmin: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/getSubsectionsAdminMain",
    getMainSectionsAdminTree: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/getSubsectionsAdminMainTree",
    getPage: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/getPage",
    addSection: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/createSection",
    deleteSection: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/deleteSection",
    updateSection: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/updateSection",
    addPage: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/createPage",
    updatePage: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/updatePage",
    deletePage: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/deletePage",
    moveSection: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/moveSection",
    downSection: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/downSection",
    getImageSection: PuertoEntorno[Entorno] + "/sifu-ot-services/api/sifu/cms/getImageSection",
    refreshToken: PuertoEntorno[Entorno] + "/sifu-ot-services/portalempleado/usuario/refreshToken",
};

export const REST_CODES = {
    UNAUTHORIZED: 401,
};

export const Holidays = [[1, 6], [], [], [], [1], [], [], [15], [], [12], [1], [6, 8, 25]];

export default { APIs };
