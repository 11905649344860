<template>
    <div>
        <div>
            <div class="breadCrumb">
                <div v-for="(value, key) in breadcrumb" :key="key" class="crumbContainers">
                    <label class="crumb" @click="restoreTo(value, key)">{{ getBreadCrumbLabel(value.id) }}<span
                            v-if="key != breadcrumb.length - 1" class="separator">/</span></label>
                </div>
                <div class="dropDownVisibility" style="float: right">
                    <label for="visibilityTreeSelect" style="margin-right: 5px">Visibilidad</label>
                    <PVDropdown id="visibilityTreeSelect" v-model="adminVisibility" :options="optionsVisibility"
                        optionLabel="name" optionValue="" @change="restVkiew()" />
                </div>
                <div class="dropDownVisibility" style="float: right; margin-right: 15px">
                    <label for="userTreeSelect" style="margin-right: 5px">Usuario</label>
                    <PVDropdown id="userTreeSelect" v-model="adminUser" :options="optionsUser" optionLabel="name"
                        optionValue="" @change="restVkiew()" />
                </div>
                <div class="dropDownVisibility" style="float: right; margin-right: 15px">
                    <label for="userTreeSelect" style="margin-right: 5px">Centro especial</label>
                    <PVDropdown id="userTreeSelect" v-model="adminEmployment" :options="specialEmployment"
                        optionLabel="name" optionValue="" @change="restVkiew()" />
                </div>
            </div>
        </div>

        <div class="cardGroup" v-if="!content.title && !loadingCards">
            <div v-for="(card, index) in cards" :key="card.id" class="card small" @click="getCardsData(card.id)" :style="{
                'background-image': `url('${card.image}')`,
            }">
                <div v-if="card.pageImportant" class="pageImportant">
                    <img class="imgNovelty" src="../../assets/novelty.png" style="width: 100px" alt="Novedad" />

                </div>
                <div class="cardBody small">
                    <!--  <div class="cardTitle">
                    </div>-->
                    <h2 class="cardTitle">{{ card.title }}</h2>
                    <p class="cardDescription" :style="cardDescriptionStyle[index]">{{ card.description }}</p>

                    <!--<div  class="cardBody">
                    </div>-->
                </div>
            </div>
        </div>
        <div class="newsGroup" v-if="!content.title && !loadingCards">
            <div v-for="news in newsList" :key="news.id" class="newsCard" @click="requestContent(news.id)"
                @beforeMount="loadNews(news)">
                <div class="newsImage" :style="{
                    'background-image': `url('${news.image}')`,
                }"></div>
                <div class="newsContent">
                    <div class="newsHeader">
                        <div class="newsDate">{{ news.date.format("DD-MM-YYYY") }}</div>
                        <div class="newsTitle">{{ news.title }}</div>
                    </div>

                    <div class="newsDescription">
                        {{ news.description }}
                    </div>
                </div>
            </div>
        </div>

        <div class="content" v-if="content.title">
          
           <div class="headImage" :style="{
                'background-image': `url('${content.image}')`,
            }">
                <h1 class="headTitle">{{ content.title }}</h1>
            </div>
       
            <p v-html="content.content"></p>
            <div v-if="mobilePage" class="mobilePage">
            </div>
        </div>

        <div style="text-align: center"
            v-if="!content.title && !loadingCards && cards.length <= 0 && newsList.length <= 0">
            <h2>No hay ninguna noticia que mostrar aquí</h2>
            <br />
            <img src="https://images.emojiterra.com/google/android-oreo/512px/2639.png" style="width: 250px" />
        </div>
    </div>
</template>
<script>
import { APIs } from "../../common/constantes.js";
//import moment from "moment";

export default {
    name: "Wellnes-Area",
    data() {
        return {
            cards: [],
            newsList: [],
            data: [],
            content: {
                image: "",
                title: "",
                content: "",
            },
            breadcrumb: [{ id: null, title: "Club del empleado/a" }],
            loadingCards: false,
            memoization: [],
            contentMemoization: [],
            nameStores: [{ id: null, label: "Club del empleado/a" }],
            visibility: 0,
            optionsVisibility: [
                { name: "Todos", code: null },
                { name: "Sin discapacidad", code: "1" },//Cod sin discapacidad o con discapacidad
                { name: "Con discapacidad", code: "2" },
            ],
            optionsUser: [
                { name: "Todos", code: null },
                { name: "Estructura", code: "1" },
                { name: "Servicios", code: "2" },
            ],
            specialEmployment: [
                { name: "Todos", code: null },
                { name: "Sí", code: "1" },
                { name: "No", code: "2" },
            ],
            adminVisibility: {},
            adminUser: {},
            adminEmployment: {},
            cardTitleLength: 7,
            mobilePage: this.usingInMobile(),
        };
    },
    methods: {
        loadNews(news) {
            console.debug("loadNews ", news);
        },
        getCardsData: async function (id) {
            //  if (this.loadingCards) return;
            let pageCode = this.cards.find((card) => card.id == id + "")?.pageCode;

            this.loadingCards = true;
            this.cards = [];
            this.newsList = [];

            if (pageCode) {
                this.requestContent(pageCode);
                return;
            }

            if (this.memoization[id]) {
                this.cards = this.memoization[id].cards;
                this.newsList = this.memoization[id].newsList;
                this.loadingCards = false;
                this.breadcrumb.push(this.memoization[id].breadcrumb);
                this.$emit("resized");
                return;
            }
            this.apiQuery(id ? APIs.getSections : APIs.getMainSections, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: id
                    ? JSON.stringify({
                        codeSubsection: id,
                        visibility: this.adminVisibility.code,
                        typeDocument: "TYPE_PAGE",
                        typeSection: "TYPE_SECTION_PAGE",
                        draft: null,
                        user: this.adminUser.code,
                        specialEmployment: this.adminEmployment.code,
                        pageValid: "Y",
                    })
                    : JSON.stringify({
                        visibility: this.adminVisibility.code,
                        typeDocument: "TYPE_PAGE",
                        typeSection: "TYPE_SECTION_PAGE",
                        draft: null,
                        user: this.adminUser.code,
                        specialEmployment: this.adminEmployment.code,
                        pageValid: "Y",
                    }),
            })
                .then((response) => response.json())
                .then((result) => {
                    result.forEach(async (card) => {
                        this.cards.push({
                            id: card.code,
                            image: card.image,
                            title: card.name,
                            description: card.description,
                            pageCode: card.pageCode,
                            pageImportant: card.pageImportant,
                        });
                        let store = this.nameStores.find((store) => store.id == card.code);
                        if (store) {
                            store.label = card.name;
                        } else {
                            this.nameStores.push({ id: card.code, label: card.name });
                        }
                    });
                    if (this.cards.length > 0) {
                        this.cards.forEach((card) => {
                            // console.log("$altura", card.id);
                            this.apiQuery(APIs.getImageSection, {
                                method: "POST",
                                headers: {
                                    accept: "application/json",
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + this.authToken,
                                },
                                body: card.id,
                            })
                                .then(async (response) => response.text())
                                .then((result) => {
                                    //  console.log("result", result);
                                    card.image = result;
                                });
                        });
                    }
                    /**
                        if (result?.listNews?.length > 0) {
                            result.listNews.forEach((news) => {
                                this.newsList.push({
                                    id: news.codeNvNews,
                                    title: news.headlineVr,
                                    description: news.descriptionTxt,
                                    html: news.htmlTxt,
                                    image: news.imageUrlVr,
                                    date: moment(news.publishDateDt),
                                });
                            });
                        }*/

                    this.loadingCards = false;
                    this.memoization[id] = {
                        cards: this.cards,
                        newsList: this.newsList,
                        breadcrumb: { id: id, title: this.nameStores.find((store) => store.id == id).label },
                    };
                    if (!this.breadcrumb.find((crumb) => crumb.id == id)) this.breadcrumb.push(this.memoization[id].breadcrumb);
                })
                .catch((error) => {
                    this.loadingCards = false;
                    console.log(error);
                });
        },
        usingInMobile: function () {
            if (navigator.userAgent.match('Android') || navigator.userAgent.match('webOS') || navigator.userAgent.match('iPhone') || navigator.userAgent.match('iPad') || navigator.userAgent.match('iPod') || navigator.userAgent.match('BlackBerry') || navigator.userAgent.match('Windows Phone')) {
                return true;
            } else {
                return false;
            }

        },
        loadCardDescriptionStyle: function (title) {
            console.debug("title", title)
        },
        restVkiew: function () {
            this.memoization = [];
            this.breadcrumb = [{ id: null, title: "Club del empleado/a" }];
            this.contentMemoization = [];
            this.nameStores = [{ id: null, label: "Club del empleado/a" }];
            this.getCardsData(null);
            this.resetContent();
        },
        requestContent: function (id) {
            this.loadingCards = true;
            if (this.contentMemoization[id]) {
                let result = this.contentMemoization[id];
                this.content.image = result.image;
                this.content.title = result.headline;
                this.content.content = result.html;
                this.$emit("resized");
                this.loadingCards = false;
                return;
            }

            this.apiQuery(APIs.getPage, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: id,
            })
                .then((response) => response.json())
                .then((result) => {
                    this.contentMemoization[id] = result;
                    this.content.image = result.image;
                    this.content.title = result.headline;
                    this.content.content = result.html;
                    this.loadingCards = false;
                    this.$emit("resized");
                })
                .catch((e) => {
                    this.loadingCards = false;
                    console.log(e);
                });
        },
        resetContent: function () {
            this.content.image = "";
            this.content.title = "";
            this.content.content = "";
        },
        goToInit: function () {
            this.cards = this.data;
            this.resetContent();
            this.$emit("resized");
        },
        getBreadCrumbLabel: function (value) {
            return this.breadcrumb.find((e) => e.id == value)?.title;
        },
        restoreTo: function (value, key) {
            this.getCardsData(value.id);
            this.breadcrumb = this.breadcrumb.slice(0, key + 1);
            this.resetContent();
            this.$emit("resized");
        },
    },
    mounted() {
        this.getCardsData(null);
        this.adminVisibility = this.optionsVisibility[0];
        this.adminUser = this.optionsUser[0];
        this.adminEmployment = this.specialEmployment[0];
       // this.mobilePage = this.usingInMobile();
    },
    computed: {
        contentShowed: function () {
            return this.$store.getters.getVisibleMenu;
        },
        authToken: function () {
            return this.$store.getters.getToken;
        },
        codHandicapped: function () {
            return this.$store.getters.getUserData.codHandicapped;
        },
        cardDescriptionStyle() {
    
            return this.cards.map((card) => {
                var minusLines = Math.round((card.title.length / 14) );
                return {
                    "--cardTitleLength": (this.cardTitleLength - minusLines < 1) ? 1 : this.cardTitleLength - minusLines,
                }
            });
        }

    },
    watch: {
        loadingCards: function (newLoading) {
            this.$isLoading(newLoading);
            this.$emit("resized");
        },
        cards: function () {
            this.$emit("resized");
        },
        newsList: function () {
            this.$emit("resized");
        },
        visibility: function () {
            this.memoization = [];
            this.restoreTo(this.breadcrumb[this.breadcrumb.length - 1], this.breadcrumb.length - 1);
        },
        contentShowed: function () {
            if (this.contentShowed == 9 || (typeof this.contentShowed == "string" && this.contentShowed.includes("9>"))) {
                if (typeof this.contentShowed == "string") {
                    if (!this.contentShowed.includes("-")) {
                        this.breadcrumb = [];
                        this.getCardsData(null);
                        this.getCardsData(this.contentShowed.slice(2, this.contentShowed.length));
                        this.$store.commit("setVisibleMenu", "9>-");
                    }
                } else {
                    this.breadcrumb = [];
                    this.getCardsData(null);
                    this.$store.commit("setVisibleMenu", "9>-");
                }
                this.resetContent();
            }
        },
    },
};

</script>
<style scoped>
/** Cards con texto en imagen */
.cardTitle {
    padding: 0;
    height: auto;
    line-height: normal;
    font-weight: bold;
    margin-top: 0.25em;
    margin-bottom: 0.25em;

}

.cardBody {
    margin: 0;
    padding: 0;

}

.cardDescription {
    margin-top: 0;
    line-height: normal;
    font-size: small;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: var(--cardTitleLength);
    -webkit-box-orient: vertical;
}

.card.small {
    height: 400px;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease;
    -webkit-box-shadow: 5px 5px 12px -1px gray;
    box-shadow: 5px 5px 12px -1px gray;
    margin-bottom: 24px;
    cursor: pointer;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/* Small Screen */
@media screen and (max-width: 788px) {
    .card.small {
        width: 100%;
        margin-right: 0;
    }
}

/* Medium Screen */
@media screen and (min-width: 788px) {
    .card.small {
        width: 48%;
        height: 350px;
        max-width: 550px;
        margin-right: 1%;
        margin-left: 1%;
    }
}

/* Normal Screen */
@media screen and (min-width: 1200px) {
    .card.small {
        width: 32%;
        height: 350px;
        max-width: 600px;
        margin-right: 0.5%;
        margin-left: 0.5%;
    }
}

/* Big Screen */
@media screen and (min-width: 1800px) {
    .card.small {
        width: 24%;
        height: 350px;
        margin-right: 0.5%;
        margin-left: 0.5%;

    }
}

/* Very Big Screen */
@media screen and (min-width: 2100px) {
    .card.small {
        width: 19%;
        max-width: 800px;

        height: 350px;
        margin-right: 0.5%;
        margin-left: 0.5%;

    }
}

/* Very Big Screen */
@media screen and (min-width: 3000px) {
    .card.small {
        max-width: 13%;

        height: 350px;
        margin-right: 0.5%;
        margin-left: 0.5%;

    }
}

.cardImage.small {
    width: 100%;
    height: 100%;
    position: absolute;
}

.cardBody.small {
    width: 70%;
    height: 60%;
    right: 4%;
    top: 35%;
    background-color: #f5f8fa;
    padding: 10px;
    position: absolute;
    overflow: hidden;
    -webkit-box-shadow: 5px 5px 12px -1px gray;
    box-shadow: 5px 5px 12px -1px gray;
}

.cardBody>h2 {
    font-size: calc(1.3rem - 0.1vw);
}



/** Cards estiradas */
.card.estirada {
    width: 350px;
    height: 550px;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease;
    -webkit-box-shadow: 5px 5px 12px -1px gray;
    box-shadow: 5px 5px 12px -1px gray;
    margin-right: 5%;
    margin-bottom: 24px;
    cursor: pointer;
    vertical-align: top;
}

.cardImage.estirada {
    width: 100%;
    height: 300px;
}

.cardBody.estirada {
    width: 100%;
    padding: 10px;
}

/** Común para ambos */

.card:hover {
    -webkit-box-shadow: 5px 5px 12px 2px rgb(100, 100, 100);
    box-shadow: 5px 5px 12px 2px rgb(100, 100, 100);
}

.cardBody>p {
    color: gray;
}

.cardBody>h2 {
    color: #002e72;
}
h2 {
    line-height: normal;
    font-weight: bold;
    margin-top: 0.25em;
    margin-bottom: 0.25em;

}
.cardGroup {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 15px;
    justify-content: flex-start;
}

.pointer {
    cursor: pointer;
}

.content {
    margin-top: 15px;
}

.breadCrumb {
    display: inline-block;
    width: 100%;
}

.crumb {
    cursor: pointer;
    transition: all 0.3s ease;
    color: #3498db;
    font-weight: normal;
}

.crumb:hover {
    color: #2772a3;
    text-decoration: underline;
}



.loadingCard {
    animation: loading 1s infinite ease;
    background-color: #fff;
    background-image: linear-gradient(90deg, #f3f3f3, #eaeaea, #f3f3f3, #eaeaea);
    background-size: 300% 100%;
    width: 350px;
    height: 300px;
    margin-right: 24px;
    margin-bottom: 24px;
    -webkit-box-shadow: 5px 5px 12px -1px #bababa;
    box-shadow: 5px 5px 12px -1px #bababa;
    cursor: pointer;
}

.newsGroup {
    display: grid;
    column-gap: 15px;
    width: 100%;
    margin-top: 15px;
    padding-right: auto;
    padding-left: auto;
}

.newsCard {
    width: 100%;
    height: 300px;
    -webkit-box-shadow: 5px 5px 12px -1px gray;
    box-shadow: 5px 5px 12px -1px gray;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 24px;
    margin-right: 24px;
    cursor: pointer;
    display: inline-flex;
}

.dropDownVisibility {
    float: right;
}

.crumbContainers {
    float: left;
}

.separator {
    color: black;
    font-weight: bolder;
    margin-left: 5px;
    margin-right: 5px;
}

/* Small Screen */
@media screen and (max-width: 788px) {
    .newsGroup {
        grid-template-columns: auto;
    }
}

/* Big Screen */
@media screen and (min-width: 788px) {
    .newsGroup {
        grid-template-columns: 50% 50%;
    }
}

.newsHeader {
    flex: 0 1 auto;
    height: auto;
}

.newsDescription {
    background-color: #f5f8fa;
    padding: 7px;
    width: calc(95% - 14px);
    overflow: hidden;
    flex: 1 1 auto;
}

.newsDate {
    background-color: #f5f8fa;
    padding: 7px;
    float: right;
}

.newsTitle {
    background-color: #f5f8fa;
    padding: 7px;
    font-weight: bolder;
    font-size: 20px;
}



.newsContent {
    background-color: #f5f8fa;
    width: 75%;
    height: 100%;
    display: flex;
    flex-flow: column;
}

.newsImage {
    width: 25%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@keyframes loading {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 100% 0;
    }
}

.headImage {
    width: calc(100% + 40px);
    height: 150px;
    margin-left: -20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;
}

.headTitle {
    background-color: white;
    border: 1px solid white;
    border-radius: 25px;
    position: absolute;
    left: 2%;
    padding: 5px;
    margin: 0px;
    margin-right: 20px;
}

.imgNovelty {
    float: left;
}
.mobilePage{
    width: 100%;
    height: 50px;
}
</style>
