<template>
    <div style="display: flex; height: 100px">
        <div>
            <a href="/"><img src="./assets/logo.png" style="width: 100px" alt="Quercus Logo" /></a>
        </div>
        <div
            style="display: flex; justify-content: center; align-items: center; font-weight: bolder; font-size: min(2vw, 52px)"
            v-if="authToken != null && authToken != '' && $route.name != 'passwordChange'"
        >
            <span style="color: black"> Portal de administración</span>
        </div>
        <div
            style="display: flex; justify-content: center; align-items: center; font-weight: bolder; font-size: 23px"
            v-if="authToken == null || authToken == '' || $route.name == 'passwordChange'"
        >
            <span style="color: black"> Portal de administración</span>
        </div>
        <div class="menuCollapse" v-show="menuCollapseVisible && authToken != null && authToken != '' && $route.name != 'passwordChange'">
            <!-- Icono menu -->
            <div @click="ToggleMenu()">
                <i style="color: black; margin-right: 10px" class="fa-3x iconoMenu" :class="menuVisibility ? 'fa-solid fa-xmark' : 'fas fa-bars'"></i>
                <!-- <font-awesome-icon icon="bars" size="2x" class="fa-fw iconoMenu"/>-->
            </div>
            <!-- fin icono menu -->
        </div>
    </div>
    <router-view></router-view>
</template>
<style>
    #app {
        font-family: "Lato", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        color: #2c3e50;
    }
</style>
<script>
    export default {
        methods: {
            resizeMenu: function () {
                this.menuCollapseVisible = document.documentElement.clientWidth < 600;
            },
            ToggleMenu: function () {
                this.$store.commit("toggleMenuVisibility");
            },
        },
        computed: {
            authToken: function () {
                return this.$store.getters.getToken;
            },
            menuVisibility: function () {
                return this.$store.getters.getMenuVisibility;
            },
        },
        data() {
            return {
                menuCollapseVisible: false,
            };
        },
        watch: {
            authToken(newToken) {
                if (newToken == "") {
                    this.$router.push({ name: "login" });
                }
            },
        },
        created() {
            window.addEventListener("resize", this.resizeMenu);
        },
        unmounted() {
            window.removeEventListener("resize", this.resizeMenu);
        },
        mounted() {
            this.resizeMenu();
        },
    };
</script>
<style scoped>
    .menuCollapse {
        margin: auto;
        margin-right: 10px;
    }
</style>
