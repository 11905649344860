<template>
    <div class="form">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <h2>
                        <span v-if="categoryCreatorData.mode == 'add'">Creación de un nuevo</span
                        ><span v-if="categoryCreatorData.mode == 'edit'">Modificación de un</span> apartado
                    </h2>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="formGroup">
                        <label for="name">Título<span class="mandatory">*</span></label>
                        <PVInputText type="text" class="p-inputtext-lg" id="name" v-model="categoryCreatorData.editableData.name" maxlength="50" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="formGroup">
                        <label for="description">Resumen<span class="mandatory">*</span></label>
                        <PVTextarea id="description" v-model="categoryCreatorData.editableData.description" maxlength="495"></PVTextarea>
                    </div>
                </div>
            </div>
            <div class="row mt-12">
                <div :class="this.imageData != null ? 'col-sm-12 col-md-9 col-lg-10' : 'col-12'">
                    <div class="formGroup">
                        <label for="coverImage">Imagen<span class="mandatory">*</span></label>

                        <PVInputText type="file" class="p-inputtext-lg" id="coverImage" ref="coverImage" @change="saveImage" />
                    </div>

                    <div v-show="this.imageData != null" class="col-9">
                        <img :src="imageData" id="imageurl" />
                    </div>
                </div>

                <div v-show="this.imageData != null" class="col-sm-12 col-md-3 col-lg-2">
                    <div class="formGroup">
                        <label for="coverImage" style="visibility: hidden"> I<span class="mandatory"></span></label>

                        <PVButton
                            class="modal-button"
                            id="modal-button-delete-img"
                            label="Borrar imagen"
                            @click="deleteImage"
                            style="width: 100%; padd"
                        ></PVButton>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="formGroup" id="formGroupButtons">
                        <PVButton class="modal-button" v-if="categoryCreatorData.mode == 'add'" @click="createCategory">
                            <i v-if="loading" class="fa-solid fa-spinner pageMargin" style="align: center"></i>
                            <span v-else style="align: center">Añadir</span>
                        </PVButton>
                        <PVButton class="modal-button" v-if="categoryCreatorData.mode == 'edit'" @click="updateCategory">
                            <i v-if="loading" class="fa-solid fa-spinner pageMargin" style="align: center"></i>
                            <span v-else style="align: center">Actualizar</span>
                        </PVButton>
                        <PVButton class="modal-button" @click="goToMainView()" style="align: center">Volver</PVButton>
                    </div>
                </div>
            </div>
            <div class="little-width offset-1" style="margin-bottom: 75px"></div>
        </div>
    </div>
</template>
<script>
import { useToast } from "vue-toastification";
import { APIs } from "../../common/constantes.js";
export default {
    name: "Category-Creator",
    props: ["data", "type"],
    data() {
        return {
            toast: useToast(),
            loading: false,
            imageData: null,
        };
    },
    methods: {
        goToMainView: function () {
            this.$store.commit("resetCategoryCreatorData");
        },
        createCategory: function () {
            if (!this.checkForm()) return;
            var emptyCount = 0;

            this.loading = true;
            this.apiQuery(APIs.addSection, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: JSON.stringify({
                    name: this.categoryCreatorData.editableData.name,
                    description: this.categoryCreatorData.editableData.description,
                    pageCode: null,
                    sectionParentCode: this.categoryCreatorData.parentCategory.id,
                    image: this.imageData,
                    type: this.type,
                    pageValid: "N",
                    pagesVisibilityWithoutDisability: emptyCount,
                    pagesVisibilityWithDisability: emptyCount,
                    pagesUsersStructure: emptyCount,
                    pagesUsersServices: emptyCount,
                    pagesUsersNoSpecialCenter: emptyCount,
                    pagesUsersSpecialCenter: emptyCount,
                }),
            })
                .then(async (response) => {
                    if (response.ok) {
                        this.$store.state.categoryLastUpdate = this.categoryCreatorData.parentCategory;
                        this.goToMainView();
                        this.toast.success("Apartado creado correctamente.", { timeout: 3000 });
                        this.loading = false;
                        this.$emit("update");
                    }
                })
                .catch((e) => {
                    this.toast.error("Ha ocurrido un error inesperado, intentalo más tarde: " + e, { timeout: 3000 });
                    this.loading = false;
                });
        },
        updateCategory: function () {
            if (!this.checkForm()) return;

            this.loading = true;
            this.apiQuery(APIs.updateSection, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: JSON.stringify({
                    code: this.$store.state.categoryCreatorData.id,
                    name: this.categoryCreatorData.editableData.name,
                    description: this.categoryCreatorData.editableData.description,
                    //pageCode: this.$store.state.categoryCreatorData.editableData.pageCode,
                    //sectionParentCode: this.$store.state.categoryCreatorData.parentCategory.id,
                    image: this.imageData,
                    //type: this.type,
                    //position: this.$store.state.categoryCreatorData.position,
                    //pageValid: this.$store.state.categoryCreatorData.pageValid,
                    //pagesVisibilityWithoutDisability: this.$store.state.categoryCreatorData.pagesVisibilityWithoutDisability,
                    //pagesVisibilityWithDisability: this.$store.state.categoryCreatorData.pagesVisibilityWithDisability,
                    //pagesUsersStructure: this.$store.state.categoryCreatorData.pagesUsersStructure,
                    //pagesUsersServices: this.$store.state.categoryCreatorData.pagesUsersServices,
                    //pagesUsersNoSpecialCenter: this.$store.state.categoryCreatorData.pagesUsersNoSpecialCenter,
                    //pagesUsersSpecialCenter: this.$store.state.categoryCreatorData.pagesUsersSpecialCenter,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        if (response.ok) {
                            this.$store.commit("resetCategoryCreatorData");

                            this.$store.state.categoryLastUpdate = this.categoryCreatorData.parentCategory.id;
                            this.goToMainView();
                            this.toast.success("Sección actualizada correctamente.", { timeout: 3000 });
                            this.loading = false;
                            this.$emit("update");
                        }
                    }
                })
                .catch((e) => {
                    this.toast.error("Ha ocurrido un error inesperado, intentalo más tarde: " + e, { timeout: 3000 });
                    this.loading = false;
                });
        },
        saveImage: function (e) {
            const fileObject = e.target.files[0]; // get first file
            const reader = new FileReader();
            reader.onload = (e) => {
                this.imageData = e.target.result;
            };
            reader.readAsDataURL(fileObject);
        },
        checkForm: function () {
            if (!this.categoryCreatorData.editableData.name || !this.categoryCreatorData.editableData.description || !this.imageData) {
                this.toast.error("Debes rellenar todos los campos obligatorios.");
                return false;
            }
            return true;
        },
        deleteImage: function () {
            this.imageData = null;
        },
    },
    computed: {
        categoryCreatorData: function () {
            return this.$store.state.categoryCreatorData;
        },
    },
    watch: {
        loading: function (newLoading) {
            this.$isLoading(newLoading);
        },
        categoryCreatorData: function () {
            this.$refs["coverImage"].value = "";
            this.imageData = null;
        },
        "categoryCreatorData.mode": function (newMode) {
            this.$refs["coverImage"].value = "";
            this.imageData = null;

            if (newMode != "edit") return;
            try {
                this.imageData = this.categoryCreatorData.editableData.image;
            } catch {
                console.log("Empty image");
            }
        },
        imageData: function () {
            this.$emit("resized");
        },
    },
};
</script>
<style scoped>
input,
label {
    display: block;
    margin-bottom: 3px;
}

.formGroup {
    text-align: left;
    display: block;
    margin-top: 25px;
}

.form {
    margin-top: 15px;
}

h2 {
    font-weight: bold;
}
textarea {
    margin-top: 0px;
    width: 100%;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-color: #a6a6a6;
}
#name {
    width: 100%;
}
#coverImage {
    width: 100%;
}
#imageurl {
    max-width: 350px;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
}
.modal-button {
    display: inline;
    text-align: center;
    width: 150px;
    margin-left: 20px;
    margin-right: 0px;
}
#formGroupButtons {
    float: right;
}
#modal-button-delete-img {
    margin-left: 0px;
    min-height: 55px;
    width: 100%;
}
@media (max-width: 599px) {
    .little-width {
        height: 5em;
    }
}
</style>
