<template>
    <div class="modal-mask" v-show="visible">
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="modal-header">
                    <h2>Confirmación</h2>
                </div>
                <div class="modal-body">
                    <h4>¿Estás seguro{{ message }}?</h4>
                    <button class="modal-button accept" @click.prevent="accept">Sí, continuar</button>
                    <button class="modal-button cancel" @click.prevent="cancel">No, cancelar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Confirmation-Box",
        props: ["visible", "message"],
        data() {
            return {};
        },
        methods: {
            accept: function () {
                this.$emit("accept");
            },
            cancel: function () {
                this.$emit("cancel");
            },
        },
    };
</script>
<style scoped>
    h4 {
        font-size: 25px;
    }

    .modal-wrapper {
        color: black;
        text-align: center;
    }

    .modal-container {
        width: 40%;
        height: 30%;
        min-height: 360px;
        min-width: 300px;
        top: 15%;
        padding: 20px;
        left: calc(50% - max(160px, 40%/2));
    }

    .accept {
        background-color: #27ae60;
        border-color: #27ae60;
        transition: all 0.3s;
    }

    .accept:hover {
        color: #ecf0f1;
    }

    .cancel {
        background-color: #c0392b;
        border-color: #c0392b;
        transition: all 0.3s;
    }

    .cancel:hover {
        color: #ecf0f1;
    }
    .modal-button {
        width: 150px;
    }
</style>
