<template>
    <div class="pageShow">
        <MenuLateral id="menuLateralComponent" :visible="showMenu" v-on:menuChange="setFooter" />
        <div class="contenido" :class="{ absolutePositionMenu: smallDevice }">
            <TreeManagement v-show="contentShowed == 1" v-on:resized="setFooter" />
            <MainWellnessAreaVue
                v-if="contentShowed == 2 || (typeof contentShowed === 'string' && contentShowed.includes('2>'))"
                v-on:resized="setFooter"
            />
            <InterestInfoVue v-if="contentShowed == 3"></InterestInfoVue>
            <TreeInterestManagementVue v-if="contentShowed == 4"></TreeInterestManagementVue>
        </div>
        <Footer :class="{ fixedFooter }" class="footer" />
    </div>
</template>

<script>
import TreeManagement from "../components/wellness-area/TreeManagement.vue";
import MenuLateral from "../components/MenuLateral.vue";
import Footer from "../components/Footer.vue";
import MainWellnessAreaVue from "../components/wellness-area/MainWellnessArea.vue";
import InterestInfoVue from "../components/wellness-area/InterestInfo.vue";
import TreeInterestManagementVue from "../components/wellness-area/TreeInterestManagement.vue";

export default {
    name: "App",
    components: {
        MenuLateral,
        TreeManagement,
        Footer,
        MainWellnessAreaVue,
        InterestInfoVue,
        TreeInterestManagementVue,
    },
    data() {
        return {
            showMenu: true,
            showMenuCompacto: false, //Revisar en el futuro utilidad de esta variable
            noticiasOn: false,
            smallDevice: false,
            fixedFooter: false,
        };
    },
    methods: {
        resizeContent: function () {
            this.smallDevice = document.documentElement.clientWidth < 600;
            this.setFooter();
        },
        setFooter: function (overflow = 0) {
            setTimeout(() => {
                let htmlHeight = document.getElementsByTagName("html")[0].offsetHeight;
                let deviceHeight = document.documentElement.clientHeight;
                if (deviceHeight > htmlHeight + 40 != this.fixedFooter) {
                    this.fixedFooter = deviceHeight > htmlHeight + 40;
                } else if (overflow <= 10) {
                    this.setFooter(overflow + 1);
                }
            }, 10);
        },
    },
    computed: {
        authToken: function () {
            return this.$store.getters.getToken;
        },
        contentShowed: function () {
            return this.$store.getters.getVisibleMenu;
        },
    },
    created() {
        window.addEventListener("resize", this.resizeContent);
    },
    unmounted() {
        window.removeEventListener("resize", this.resizeContent);
    },
    mounted() {
        this.resizeContent();
        this.$store.commit("setVisibleMenu", 1);
        //if (this.smallDevice) this.$store.commit("setVisibleMenu", 1);
        //else this.$store.commit("setVisibleMenu", 2);
    },
};
</script>

<style>
.noticiaLink {
    text-decoration: none !important;
}
.noticiaNoLink {
    pointer-events: none;
    cursor: default;
    text-decoration: none !important;
}

.pageShow {
    display: flex;
    flex-flow: column;
    height: 100%;
    position: relative;
}

#menuLateralComponent {
    flex: 0 1 auto;
    z-index: 1000;
    width: 100%;
}

.contenido {
    padding: 20px;
    height: max(auto, 500px);
}

.absolutePositionMenu {
    position: absolute;
    top: 100px;
    /*width: calc(100% - 60px);*/
    overflow: auto;
}

.fixedFooter {
    bottom: 0;
    position: fixed;
}

.footer {
    bottom: 0;
    display: inline;
}

@import "../../public/styles/styles.css";
</style>
