<template>
    <div class="leftMargin">
        <CategoryCreator v-show="categoryCreatorData.visible == 'TYPE_DOCUMENT'" :data="categoryCreatorData"
            v-on:resized="$emit('resized')" v-on:update="update" :type="'TYPE_SECTION_DOCUMENT'" />
        <PageCreator v-show="pageCreatorData.visible == 'TYPE_DOCUMENT'" :data="pageCreatorData" v-on:update="update"
            :type="'TYPE_DOCUMENT'" :typeSection="'TYPE_SECTION_DOCUMENT'" />
        <div v-show="pageCreatorData.visible != 'TYPE_DOCUMENT' && categoryCreatorData.visible != 'TYPE_DOCUMENT'">
            <ul>
                <li>
                    <div class="liContainer">
                        <h2>
                            <i v-if="loading" class="fa-solid fa-spinner"></i><i v-else
                                class="fa-solid fa-spa"></i>Gestión información de interés
                        </h2>
                        <div class="tooltipExecutor">
                            <PVButton @click="addCategory(null)" icon="fa-solid fa-plus" class="p-button-success"
                                title="Añadir apartado"></PVButton>
                        </div>
                        <div class="SaveOrder">
                            <div style="display: flex; flex-direction: column;float: right; margin-right: 2%;">
                                <PVButton @click="saveMoves()" :disabled="moveQueue.length == 0"
                                    :class="{ orangeBtn: moveQueue.length != 0 }" class="home-table-link saveOrderButton"
                                    title="Guardar" label="Guardar"></PVButton>
                                <span v-if="moveQueue.length != 0"><i>¡Tienes cambios sin guardar!</i></span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div>
                <PVConfirmDialog></PVConfirmDialog>
                <PVConfirmDialog group="positionDialog"></PVConfirmDialog>

                <PVTreeTable style="width: 98%; " :value="nodes" :lazy="true" @nodeExpand="onExpand" @node-collapse="onCollapse">
                    <PVColumn class="columName"
                        style="overflow: hidden; text-overflow: ellipsis; height: 0.5em; width: 65%; border-width: 1px 0px 1px 1px;" field="name"
                        header="Nombre" :expander="true">
                        <template #body="slotProps">
                            <span class="spanName"> <!--:style="[slotProps.node.data.pageImportant == true ? 'font-weight: bold' : '']"-->
                                <i v-if="slotProps.node.leaf" class="pi pi-file"></i>
                                {{ slotProps.node.data.name }}
                            </span>
                        </template>
                    </PVColumn>
                    <PVColumn style="height: 0.5em; text-align: left; border-width: 1px 1px 1px 0px;">
                        <template #header>
                            <span>Acciones</span>
                        </template>
                        <template #body="slotProps">
                            <div class="allAcctions" style="width: auto; display: inline-flex">
                                <PVButton @click="
                                    slotProps.node.data.lastChildren
                                        ? ''
                                        : addMoveToQueue(
                                            slotProps.node.data.position,
                                            slotProps.node.data.position + 1,
                                            slotProps.node.data.parentCard
                                        )
                                " style="background-color: lightgray; border: none; box-shadow: none"
                                    icon="pi pi-arrow-up" title="Subir apartado" v-show="!slotProps.node.leaf">
                                </PVButton>
                                <PVButton @click="
                                    slotProps.node.data.position != 1
                                        ? addMoveToQueue(
                                            slotProps.node.data.position,
                                            slotProps.node.data.position - 1,
                                            slotProps.node.data.parentCard
                                        )
                                        : ''
                                " style="background-color: lightgray; border: none; box-shadow: none"
                                    icon="pi pi-arrow-down" title="Bajar apartado" v-show="!slotProps.node.leaf">
                                </PVButton>
                                <div v-show="!slotProps.node.leaf" class="acctionsCategory">
                                    <div v-if="
                                        !slotProps.node.leaf &&
                                        !slotProps.node.data.pageCode &&
                                        slotProps.node.children != null &&
                                        slotProps.node.children.length == 0
                                    " class="createAcctions">
                                        <PVButton @click="addCategory(slotProps.node.data.id)" icon="fa-solid fa-plus"
                                            class="p-button-success" title="Añadir apartado"></PVButton>
                                        <PVButton @click="addPage(slotProps.node.data.id, slotProps.node.data.position)"
                                            icon="fa-solid fa-file-circle-plus" class="p-button-success"
                                            title="Añadir página"></PVButton>
                                        <PVButton
                                            @click="editCategory(slotProps.node.data.id, slotProps.node.data.position)"
                                            icon="pi pi-pencil" class="home-table-link" title="Editar apartado">
                                        </PVButton>
                                        <PVButton @click="deleteCategory(slotProps.node.data.id)"
                                            icon="fa-solid fa-trash" class="p-button-danger"
                                            title="Eliminar apartado" />
                                    </div>
                                    <div v-else class="editAcctions">
                                        <PVButton v-show="!slotProps.node.leaf && !slotProps.node.data.pageCode"
                                            @click="addCategory(slotProps.node.data.id)" icon="fa-solid fa-plus"
                                            class="p-button-success" title="Añadir apartado"></PVButton>
                                        <PVButton
                                            @click="editCategory(slotProps.node.data.id, slotProps.node.data.position)"
                                            icon="pi pi-pencil" class="home-table-link" title="Editar apartado">
                                        </PVButton>
                                        <PVButton @click="deleteCategory(slotProps.node.data.id)"
                                            icon="fa-solid fa-trash" class="p-button-danger"
                                            title="Eliminar apartado" />
                                    </div>
                                </div>
                                <div class="acctionsPage" v-show="slotProps.node.leaf">
                                    <PVButton @click="editPage(slotProps.node.data.id)" icon="pi pi-pencil"
                                        class="home-table-link" title="Editar página"></PVButton>
                                    <PVButton @click="deletePage(slotProps.node.data.id)" icon="fa-solid fa-trash"
                                        class="p-button-danger" title="Eliminar página"></PVButton>
                                </div>
                            </div>
                        </template>
                    </PVColumn>
                </PVTreeTable>
                <div class="little-width offset-1" style="margin-bottom: 75px"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { APIs } from "../../common/constantes.js";
import CategoryCreator from "./CategoryCreator.vue";
import PageCreator from "./PageCreator.vue";
import { useToast } from "vue-toastification";

export default {
    name: "Tree-Management",
    components: { CategoryCreator, PageCreator },
    data() {
        return {
            toast: useToast(),
            nodes: [],
            cardNames: null,
            loading: false,
            moveQueue: [],
            optionsVisibility: [
                { name: "Todos", code: "0" },
                { name: "Con discapacidad", code: "1" },
                { name: "Sin discapacidad", code: "2" },
            ],
            optionsUser: [
                { name: "Todos", code: "0" },
                { name: "Estructura", code: "1" },
                { name: "Servicios", code: "2" },
            ],
            specialEmployment: [
                { name: "Todos", code: "0" },
                { name: "Sí", code: "1" },
                { name: "No", code: "2" },

            ],
        };
    },
    mounted() {
        this.getCards();
    },

    methods: {
        getCards: async function () {
            this.loading = true;
            this.nodes = [];
            this.apiQuery(APIs.getMainSectionsAdmin, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: JSON.stringify({
                    typeDocument: "TYPE_DOCUMENT",
                    typeSection: "TYPE_SECTION_DOCUMENT",
                }),
            })
                .then((response) => response.json())

                .then((result) => {
                    result.forEach((card) => {
                        if (card.pageCode) {
                            this.nodes.push({
                                key: card.code,
                                data: {
                                    name: card.name,
                                    id: card.code,
                                    type: card.typeNode,
                                    parentId: card.codeParent,
                                    position: card.position,
                                    pageImportant: card.pageImportant,
                                    lastChildren: result.length == card.position ? true : false,
                                    pageCode: card.pageCode,
                                    parentCard: null,
                                    pageValid: card.pageValid,
                                },
                                leaf: false,
                                children: [
                                    {
                                        key: card.pageCode,
                                        data: {
                                            name: card.childrensSections[0].name,
                                            id: card.pageCode,
                                        },
                                        leaf: true,
                                    },
                                ],
                            });
                        } else {
                            let nodeId = this.nodes.push({
                                key: card.code,
                                data: {
                                    name: card.name,
                                    id: card.code,
                                    type: card.typeNode,
                                    parentId: card.codeParent,
                                    position: card.position,
                                    pageImportant: card.pageImportant,
                                    lastChildren: result.length == card.position ? true : false,
                                    pageCode: card.pageCode,
                                    parentCard: null,
                                    pageValid: card.pageValid,
                                },
                                leaf: false,
                            });
                            this.getChilds(this.nodes[nodeId - 1], false);
                        }
                    });
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getChilds: async function (parentNode, load = true) {
            if (parentNode.children) return;
            if (load) this.loading = load;

            this.apiQuery(APIs.getSectionsAdmin, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: JSON.stringify({
                    typeDocument: "TYPE_DOCUMENT",
                    typeSection: "TYPE_SECTION_DOCUMENT",
                    codeSubsection: parentNode.data.id,
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    parentNode.children = [];
                    if (result && result.length > 0) {
                        result.forEach((e) => {
                            const card = e;
                            if (card.pageCode) {
                                parentNode.children.push({
                                    key: card.code,
                                    data: {
                                        name: card.name,
                                        id: card.code,
                                        type: card.typeNode,
                                        parentId: card.codeParent,
                                        position: card.position,
                                        pageImportant: card.pageImportant,
                                        lastChildren: result.length == card.position ? true : false,
                                        pageCode: card.pageCode,
                                        parentCard: parentNode,
                                    },
                                    leaf: false,
                                    children: [
                                        {
                                            key: card.pageCode,
                                            data: {
                                                name: card.childrensSections[0].name,
                                                id: card.pageCode,
                                            },
                                            leaf: true,
                                        },
                                    ],
                                });
                            } else {
                                let nodeId = parentNode.children.push({
                                    key: card.code,
                                    data: {
                                        name: card.name,
                                        id: card.code,
                                        type: card.typeNode,
                                        parentId: card.codeParent,
                                        position: card.position,
                                        pageImportant: card.pageImportant,
                                        lastChildren: result.length == card.position ? true : false,
                                        pageCode: card.pageCode,
                                        parentCard: parentNode,
                                    },
                                    leaf: false,
                                });
                                this.getChilds(parentNode.children[nodeId - 1], false);
                            }
                        });
                    }
                    if (load) this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    if (load) this.loading = false;
                });
        },
        update: function () {
            this.getCards();
            this.moveQueue = [];
        },
        addPage: function (id, position) {
            this.$store.commit("resetPageCreatorData");
            this.loading = true;
            this.apiQuery(APIs.getSection, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: id,
            })
                .then((response) => response.json())
                .then((result) => {
                    this.$store.state.pageCreatorData.section.imageData = result.image;
                    this.$store.state.pageCreatorData.section.parentId = result.sectionParentCode;
                    this.$store.state.pageCreatorData.section.pageCode = result.pageCode;
                    this.$store.state.pageCreatorData.section.description = result.description;
                    this.$store.state.pageCreatorData.section.name = result.name;
                    this.$store.state.pageCreatorData.section.id = id;
                    this.$store.state.pageCreatorData.parentCategory.id = id;
                    this.$store.state.pageCreatorData.mode = "add";
                    this.$store.state.pageCreatorData.visible = "TYPE_DOCUMENT";
                    this.$store.state.pageCreatorData.position = position;
                    this.$store.state.pageCreatorData.section.pageValid = result.pageValid;
                    this.loading = false;
                    this.$store.state.categoryCreatorData.pagesVisibilityWithoutDisability = result.pagesVisibilityWithoutDisability;
                    this.$store.state.categoryCreatorData.pagesVisibilityWithDisability = result.pagesVisibilityWithDisability;
                    this.$store.state.categoryCreatorData.pagesUsersStructure = result.pagesUsersStructure;
                    this.$store.state.categoryCreatorData.pagesUsersServices = result.pagesUsersServices;
                    this.$store.state.categoryCreatorData.pagesUsersNoSpecialCenter = result.pagesUsersNoSpecialCenter,
                        this.$store.state.categoryCreatorData.pagesUsersSpecialCenter = result.pagesUsersSpecialCenter
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.showChilds = true;
                });
        },
        addCategory: function (id) {
            this.$store.commit("resetCategoryCreatorData");
            this.$store.state.categoryCreatorData.parentCategory.id = id;
            this.$store.state.categoryCreatorData.mode = "add";
            this.$store.state.categoryCreatorData.visible = "TYPE_DOCUMENT";
        },
        editPage: function (id) {
            this.loading = true;
            this.apiQuery(APIs.getPage, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: id,
            })
                .then((response) => response.json())
                .then((result) => {

                    this.$store.state.pageCreatorData.parentCategory.id = id;
                    this.$store.state.pageCreatorData.parentCategory.name = result.headline;
                    this.$store.state.pageCreatorData.parentCategory.dateStart = result.publishDate.slice(0, 10);
                    this.$store.state.pageCreatorData.parentCategory.dateEnd = !result.validityDate ? "" : result.validityDate.slice(0, 10);
                    this.$store.state.pageCreatorData.parentCategory.visibility = this.optionsVisibility[result.visibility];
                    this.$store.state.pageCreatorData.parentCategory.image = result.image;
                    this.$store.state.pageCreatorData.parentCategory.description = result.description;
                    this.$store.state.pageCreatorData.parentCategory.html = result.html;
                    this.$store.state.pageCreatorData.mode = "edit";
                    this.$store.state.pageCreatorData.visible = "TYPE_DOCUMENT";
                    this.$store.state.pageCreatorData.parentCategory.checkedImportant = result.important == "Y" ? true : false;
                    this.$store.state.pageCreatorData.parentCategory.optionsUser = this.optionsUser[result.user];
                    this.$store.state.pageCreatorData.section.id = id;
                    this.$store.state.pageCreatorData.parentCategory.specialEmployment = this.specialEmployment[result.specialEmploymentCenter];
                    this.$store.state.categoryCreatorData.pagesUsersNoSpecialCenter = result.pagesUsersNoSpecialCenter,
                    this.$store.state.categoryCreatorData.pagesUsersSpecialCenter = result.pagesUsersSpecialCenter

                    this.loading = false;

                    //  console.log("this.$store.state.pageCreatorData.pageCreatorData", this.$store.state.pageCreatorData.parentCategory);
                })
                .catch((e) => {
                    console.log(e);
                    this.loading = false;
                    this.showChilds = true;
                });
        },
        editCategory: function (id, position) {
            this.loading = true;
            this.apiQuery(APIs.getSection, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: id,
            })
                .then(async (response) => {
                    if (response.ok || response.accept) {
                        let result = await response.json();

                        this.$store.commit("resetCategoryCreatorData");
                        this.$store.state.categoryCreatorData.id = id;
                        this.$store.state.categoryCreatorData.parentCategory.id = result.sectionParentCode;
                        this.$store.state.categoryCreatorData.editableData.name = result.name;
                        this.$store.state.categoryCreatorData.editableData.description = result.description;
                        this.$store.state.categoryCreatorData.editableData.pageCode = result.pageCode;
                        this.$store.state.categoryCreatorData.editableData.image = result.image;
                        this.$store.state.categoryCreatorData.mode = "edit";
                        this.$store.state.categoryCreatorData.visible = "TYPE_DOCUMENT";
                        this.$store.state.categoryCreatorData.position = position;
                        this.$store.state.categoryCreatorData.pageValid = result.pageValid;
                        this.$store.state.categoryCreatorData.pagesVisibilityWithoutDisability = result.pagesVisibilityWithoutDisability;
                        this.$store.state.categoryCreatorData.pagesVisibilityWithDisability = result.pagesVisibilityWithDisability;
                        this.$store.state.categoryCreatorData.pagesUsersStructure = result.pagesUsersStructure;
                        this.$store.state.categoryCreatorData.pagesUsersServices = result.pagesUsersServices;
                        this.$store.state.categoryCreatorData.pagesUsersNoSpecialCenter = result.pagesUsersNoSpecialCenter;
                        this.$store.state.categoryCreatorData.pagesUsersSpecialCenter = result.pagesUsersSpecialCenter;

                    }

                    this.loading = false;
                    this.showChilds = true;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.showChilds = true;
                });
        },
        deletePage(id) {
            this.$confirm.require({
                message: "¿Estas seguro que deseas eliminar la página?",
                header: "Confirmación",
                icon: "pi pi-exclamation-triangle",
                rejectClass: "p-button-danger spanRejectButton",
                acceptClass: "p-button p-component p-confirm-dialog-reject p-button-text",
                acceptLabel: "Si",
                accept: () => {
                    this.loading = true;
                    this.apiQuery(APIs.deletePage, {
                        method: "POST",
                        headers: {
                            accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + this.authToken,
                        },
                        body: id,
                    })
                        .then(() => {

                            this.update();
                            this.toast.success("Página eliminada correctamente", { timeout: 3000 });
                        })
                        .catch((e) => {
                            this.toast.error("Ha ocurrido un error inesperado: " + e, { timeout: 3000 });
                            this.loading = false;
                        });
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
        deleteCategory: function (id) {
            this.$confirm.require({
                message: "¿Estas seguro que deseas eliminar este apartado y todo lo que contiene?",
                header: "Confirmación",
                icon: "pi pi-exclamation-triangle",
                rejectClass: "p-button-danger spanRejectButton",
                acceptClass: "p-button p-component p-confirm-dialog-reject p-button-text",
                acceptLabel: "Si",

                accept: () => {
                    this.loading = true;
                    this.apiQuery(APIs.deleteSection, {
                        method: "POST",
                        headers: {
                            accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + this.authToken,
                        },
                        body: id,
                    })
                        .then(() => {
                            this.update();
                            this.toast.success("Apartado eliminado correctamente", { timeout: 3000 });
                        })
                        .catch((e) => {
                            this.toast.error("Ha ocurrido un error inesperado: " + e, { timeout: 3000 });
                            this.loading = false;
                        });
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
        addMoveToQueue: function (upNode, downNode, nodes) {
            if (nodes == null) {
                nodes = this.nodes;
            } else {
                nodes = nodes.children;
            }
            upNode = nodes[nodes.length - upNode];
            downNode = nodes[nodes.length - downNode];

            const keyToUp = upNode.key;
            const keyToDown = downNode.key;

            this.moveQueue.push({
                keyToUp,
                keyToDown,
            });
            const up = upNode;
            const down = downNode;

            const upPosition = nodes.indexOf(up);
            const downPosition = nodes.indexOf(down);

            const upDataPosition = nodes[upPosition].data.position;
            nodes[upPosition].data.position = nodes[downPosition].data.position;
            nodes[downPosition].data.position = upDataPosition;

            if (up.data.lastChildren || down.data.lastChildren) {
                up.data.lastChildren = !up.data.lastChildren;
                down.data.lastChildren = !down.data.lastChildren;
            }

            nodes[upPosition] = down;
            nodes[downPosition] = up;
        },
        saveMoves: async function () {
            await this.moveSection(this.moveQueue);

            this.update();
            this.moveQueue = [];
        },
        moveSection: function (moveQueue) {
            this.loading = true;
            return this.apiQuery(APIs.moveSection, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: JSON.stringify(moveQueue),
            }).catch((e) => {
                this.toast.error("Ha ocurrido un error inesperado: " + e, { timeout: 3000 });
                this.loading = false;
            });
        },

        closeConfirmBox: function () {
            this.confirmBoxVisibility = false;
        },
        onExpand: function (node) {
            this.getChilds(node);
            this.$emit("resized");
        },
        onCollapse: function () {
            this.$emit("resized");
        },
    },

    computed: {
        categoryCreatorData: function () {
            return this.$store.state.categoryCreatorData;
        },
        pageCreatorData: function () {
            return this.$store.state.pageCreatorData;
        },
        contentShowed: function () {
            return this.$store.getters.getVisibleMenu;
        },
        authToken: function () {
            return this.$store.getters.getToken;
        },
    },
    watch: {
        loading: function (newLoading) {
            this.$isLoading(newLoading);
        },
        "categoryCreatorData.visible": function () {
            this.$emit("resized");
        },
        "pageCreatorData.visible": function () {
            this.$emit("resized");
        },
        "$store.state.categoryLastUpdate": function (lastCategory) {
            if (lastCategory && lastCategory == "main") {
                this.update();
                this.$store.state.categoryLastUpdate = "";
            }
        },
        contentShowed: function (newContentShowed, oldContentShowed) {
            if (oldContentShowed == 1 && oldContentShowed != newContentShowed) {
                this.$store.commit("resetCategoryCreatorData");
                this.$store.commit("resetPageCreatorData");
            }
        },
    },
};
</script>

<style scoped>
.accept:hover {
    color: #ecf0f1;
}

Button {
    margin-right: 5px;
}

li {
    list-style-type: none;
}

.leftMargin {
    margin-left: 50px;
}

.tooltipExecutor {
    margin-left: 20px;
    margin-top: 5px;
    cursor: pointer;
}

.tooltipExecutor .tooltip2 {
    visibility: hidden;
    position: absolute;
    background-color: white;
    -webkit-box-shadow: 5px 5px 12px -1px gray;
    box-shadow: 5px 5px 12px -1px gray;
    transition: 0.3s all ease;
}

.tooltipExecutor:hover .tooltip2 {
    visibility: visible;
}

.tooltipItem {
    transition: 0.3s all ease;
    padding: 9px;
}

.tooltipItem>span {
    font-size: 18px;
}

.tooltipItem:hover {
    background-color: #bdc3c76e;
}

.liContainer {
    display: flex;
}

.fa-solid,
.fas {
    font-family: "Font Awesome 6 Free";
    font-weight: 900;

    margin-right: 10px;
}

.spanName {
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.SaveOrder {
    margin-left: auto;
    width: 100%;
}

.orangeBtn {
    background-color: #e67e22;
}
.saveOrderButton{
    width: 150px;
}
</style>
