import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import Login from "./views/Login.vue";
import MainApp from "./views/MainApp.vue";
import ChangePassword from "./views/ChangePassword.vue";
import PasswordRecovery from "./views/PasswordRecovery.vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { store } from "./store/store";
import { apiQuery } from "./common/apiInterceptor";
import ConfirmationService from "primevue/confirmationservice";

// Components PRIMEVUE
import PrimeVue from "primevue/config";
import TreeTable from "primevue/treetable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import TreeSelect from "primevue/treeselect";
import ConfirmDialog from "primevue/confirmdialog";
import loading from "vue3-loading-screen";
import Checkbox from "primevue/checkbox";
import ToggleButton from "primevue/togglebutton";
import InputSwitch from "primevue/inputswitch";

const routes = [
    { path: "/portalAdmin", component: MainApp, name: "home" },
    { path: "/portalAdmin/login", component: Login, name: "login" },
    {
        path: "/portalAdmin/changepassword",
        component: ChangePassword,
        name: "passwordChange",
    },
    {
        path: "/portalAdmin/passwordrecovery",
        component: PasswordRecovery,
        name: "passwordRecovery",
    },
    { path: "/", redirect: "/portalAdmin" },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to) => {
    const token = store.state.authorizationToken;
    if (token != "") {
        if (store.state.mustChangePassword && to.name != "passwordChange") {
            return "/portalAdmin/changepassword";
        } else if (to.name == "login") {
            return "/portalAdmin";
        }
    } else if (to.name != "login" && to.name != "passwordRecovery") {
        return "/portalAdmin/login";
    }
});

let newApp = createApp(App);
newApp.use(ConfirmationService);
newApp.use(PrimeVue);
newApp.use(router);

newApp.use(store);
newApp.use(Toast, {});

newApp.component("PVDropdown", Dropdown);
newApp.component("PVButton", Button);
newApp.component("PVTreeTable", TreeTable);
newApp.component("PVColumn", Column);
newApp.component("PVInputText", InputText);
newApp.component("PVTextarea", Textarea);
newApp.component("PVTreeSelect", TreeSelect);
newApp.component("PVCheckbox", Checkbox);
newApp.component("PVConfirmDialog", ConfirmDialog);
newApp.component("PVToggleButton", ToggleButton);
newApp.component("PVInputSwitch", InputSwitch);

newApp.mixin({
    methods: {
        apiQuery: apiQuery,
    },
});

newApp.use(CKEditor);

newApp.mount("#app");
newApp.use(loading, {
    bg: "rgba(0, 46, 114, 0.5)",
    slot: `
      <divd
      style='text-align: center; color: white; 
        font-family: "Lato", "Trebuchet MS", "Lucida Sans Unicode",
        "Lucida Grande", "Lucida Sans", Arial, sans-serif;'
        >
        <h3 class="text-3xl text-white"><i class="fa fa-refresh fa-spin fa-3x"></i><br>Cargando</h3>
      </div>
    `,
});
