<template>
    <div class="container-fluid form">
        <div class="row">
            <div class="col-12">
                <h2>
                    <span v-if="pageCreatorData.mode == 'add'">Creación </span><span
                        v-if="pageCreatorData.mode == 'edit'">Modificación </span>
                    <span>de una nueva página</span>
                </h2>
            </div>
        </div>
        <div class="row mt-12">
            <div class="col-sm-12 col-md-10">
                <div class="formGroup">
                    <label for="name">Título<span class="mandatory">*</span></label>
                    <PVInputText type="text" class="p-inputtext-lg" id="name"
                        v-model="pageCreatorData.parentCategory.name" maxlength="70" />
                </div>
            </div>
            <div class="col-sm-12 col-md-2">
                <div class="formGroup">
                    <label for="binary">Relevante</label>
                    <PVInputSwitch class="switchImportant" v-model="pageCreatorData.parentCategory.checkedImportant"
                        :checked="pageCreatorData.parentCategory.checkedImportant" />
                    <!--  <PVCheckbox class="checkImportant" inputId="binary" v-model="checkedImportant" :binary="true" :checked="checkedImportant" />-->
                </div>
            </div>
            <div class="col-sm-12 col-md-2">
                <div class="formGroup">
                    <label for="visibilityTreeSelect">Visibilidad<span class="mandatory">*</span></label>
                    <PVDropdown id="visibilityTreeSelect" v-model="pageCreatorData.parentCategory.visibility"
                        :options="optionsVisibility" optionLabel="name" placeholder="" style="width: 100%" />
                </div>
            </div>
            <div class="col-sm-12 col-md-2">
                <div class="formGroup">
                    <label for="visibilityTreeSelect">Usuarios<span class="mandatory">*</span></label>
                    <PVDropdown id="visibilityTreeSelect" v-model="pageCreatorData.parentCategory.optionsUser"
                        :options="optionsUser" optionLabel="name" placeholder="" style="width: 100%" />
                </div>
            </div>
            <div class="col-sm-12 col-md-2">
                <div class="formGroup">
                    <label for="visibilityTreeSelect">Centro especial de empleo<span class="mandatory">*</span></label>
                    <PVDropdown id="visibilityTreeSelect" v-model="pageCreatorData.parentCategory.specialEmployment"
                        :options="specialEmployment" optionLabel="name" placeholder="" style="width: 100%" />
                </div>
            </div>
            <div class="col-sm-12 col-md-2">
                <div class="formGroup">
                    <label for="binary">Estado</label>
                    <PVToggleButton style="height: 50px" v-model="draft" onLabel="Borrador" offLabel="Borrador"
                        @change="stateChange(true)" />
                    <PVToggleButton style="height: 50px" v-model="published" onLabel="Publicado" offLabel="Publicado"
                        @change="stateChange(false)" />
                </div>
            </div>
            <div class="col-sm-12 col-md-2">
                <div class="formGroup">
                    <label for="dateStart">Fecha inicio<span class="mandatory">*</span></label>
                    <PVInputText type="date" class="p-inputtext-lg-date" id="dateStart"
                        v-model="pageCreatorData.parentCategory.dateStart" />
                </div>
            </div>
            <div class="col-sm-12 col-md-2">
                <div class="formGroup">
                    <label for="dateEnd">Fecha fin</label>
                    <PVInputText type="date" class="p-inputtext-lg-date" id="dateEnd"
                        v-model="pageCreatorData.parentCategory.dateEnd"
                        @change="formatDate(pageCreatorData.parentCategory.dateEnd, 'change')">
                        {{pageCreatorData.parentCategory.dateEnd}}</PVInputText>
                </div>
            </div>
        </div>
        <div class="row mt-12">
            <div :class="this.image != null ? 'col-sm-12 col-md-9 col-lg-10' : 'col-12'">
                <div class="formGroup">
                    <label for="coverImage">Imagen de cabecera</label>

                    <PVInputText type="file" class="p-inputtext-lg" id="coverImage" ref="coverImage"
                        @change="saveImage" />
                </div>
                <div v-show="this.image != null" class="col-12">
                    <img id="imageurl" :src="image" />
                </div>
            </div>
            <div v-show="this.image != null" style="margin-left: 0" class="col-sm-12 col-md-3 col-lg-2">
                <div class="formGroup">
                    <label for="coverImage" style="visibility: hidden"> I<span class="mandatory"></span></label>
                    <PVButton class="modal-button" id="modal-button-delete-img" label="Borrar imagen"
                        @click="deleteImage"></PVButton>
                </div>
            </div>
        </div>
        <!--
        <div class="row">
            <div class="col-3">
                <label for="fileConverter">Base 64 converter</label>
                <input id="fileConverter" @change="setFileConverter" type="file" />
            </div>
            <div class="col-3">
                <PVButton class="modal-button" @click="copyBase64" :disabled="!fileConverterData" style="margin-top: 24px">Copiar Base64</PVButton>
            </div>
        </div>
        -->
        <div class="row">
            <div class="col-12">
                <div class="formGroup">
                    <label for="editor">Contenido<span class="mandatory">*</span></label>
                    <ckeditor :editor="editor" v-model="pageCreatorData.parentCategory.html" :config="editorConfig">
                    </ckeditor>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="formGroup" id="formGroupButtons">
                    <PVButton class="modal-button" @click="addPage" v-if="pageCreatorData.mode == 'add'">
                        <i v-if="loading" class="fa-solid fa-spinner pageMargin"></i>
                        <span v-else>Añadir</span>
                    </PVButton>
                    <PVButton class="modal-button" @click="updatePage" v-if="pageCreatorData.mode == 'edit'">
                        <i v-if="loading" class="fa-solid fa-spinner pageMargin"></i>
                        <span v-else>Actualizar</span>
                    </PVButton>
                    <PVButton class="modal-button" @click="goToMainView()">Volver</PVButton>
                </div>
            </div>
            <div class="little-width offset-1" style="margin-bottom: 75px"></div>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { APIs } from "../../common/constantes.js";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import ImageResizeEditing from "@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting";
import ImageResizeHandles from "@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles";
import GeneralHtmlSupport from "@ckeditor/ckeditor5-html-support/src/generalhtmlsupport";
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import PDFUploader from "../../common/ckeditor/PDFUploader";
import moment from 'moment';
export default {
    name: "Page-Creator",
    props: ["data", "type", "typeSection"],
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                height: "50%",
                plugins: [
                    Essentials,
                    Base64UploadAdapter,
                    Autoformat,
                    Bold,
                    Italic,
                    BlockQuote,
                    Heading,
                    Image,
                    ImageCaption,
                    ImageStyle,
                    ImageToolbar,
                    ImageUpload,
                    Link,
                    List,
                    Paragraph,
                    Alignment,
                    ImageResizeEditing,
                    ImageResizeHandles,
                    GeneralHtmlSupport,
                    SourceEditing,
                    MediaEmbed,
                    PDFUploader,
                ],

                toolbar: {
                    items: [
                        "heading",
                        "|",
                        "alignment", // <--- ADDED
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "undo",
                        "redo",
                        "|",
                        "uploadImage",
                        "blockQuote",
                        "|",
                        "mediaEmbed",
                        "|",
                        "sourceEditing",
                        "pdfUpload",
                    ],
                },
                image: {
                    toolbar: ["imageStyle:inline", "imageStyle:block", "imageStyle:side", "|", "toggleImageCaption", "imageTextAlternative"],
                    upload: {
                        types: ["jpeg", "png", "gif", "bmp", "webp", "tiff", "svg+xml"],
                    },
                },

                htmlSupport: {
                    allow: [
                        {
                            name: /.*/,
                            attributes: true,
                            classes: true,
                            styles: true,
                        },
                    ],
                },

                mediaEmbed: {
                    previewsInData: true,
                    extraProviders: [
                        {
                            name: "AllowPDF",
                            url: /.*\.pdf$/,
                            html: (match) => {
                                return (
                                    '<div style="position:relative; padding-bottom:100%; height:0">' +
                                    '<iframe src="' +
                                    match[0] +
                                    '" frameborder="0" ' +
                                    'style="position:absolute; width:100%; height:100%; top:0; left:0">' +
                                    "</iframe>" +
                                    "</div>"
                                );
                            },
                        },
                    ],
                    providers: [
                        {

                            name: 'youtube',

                            url: [

                                /^(?:m\.)?youtube\.com\/watch\?v=([\w-]+)(?:&t=(\d+))?/,

                                /^(?:m\.)?youtube\.com\/v\/([\w-]+)(?:\?t=(\d+))?/,

                                /^youtube\.com\/embed\/([\w-]+)(?:\?start=(\d+))?/,

                                /^youtu\.be\/([\w-]+)(?:\?t=(\d+))?/

                            ],

                            html: match => {

                                const id = match[1];

                                const time = match[2];



                                return (

                                    '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +

                                    `<iframe src="https://www.youtube.com/embed/${id}${time ? `?start=${time}` : ''}" ` +

                                    'style="position: absolute; width: 93%; height: 93%; top: 3.5%; left: 3.5%;" ' +

                                    'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +

                                    '</iframe>' +

                                    '</div>'

                                );

                            }

                        },
                    ],
                },
            },
            loading: false,
            id: null,
            name: "",
            dateStart: "",
            dateEnd: "",
            visibility: "",
            image: null,
            html: "",
            toast: useToast(),
            visibilityTreeSelect: null,
            fileConverterData: null,
            optionsVisibility: [
                { name: "Todos", code: "0" },
                { name: "Con discapacidad", code: "1" },
                { name: "Sin discapacidad", code: "2" },
            ],
            optionsUser: [
                { name: "Todos", code: "0" },
                { name: "Estructura", code: "1" },
                { name: "Servicios", code: "2" },
            ],
            specialEmployment: [
                { name: "Todos", code: "0" },
                { name: "Sí", code: "1" },
                { name: "No", code: "2" },

            ],
            checked: false,
            draft: true,
            published: false,
            startDateFormat: new Date(),
            //checkedImportant: this.pageCreatorData.parentCategory.checkedImportant,
        };
    },
    methods: {
        formatDate: function (date, msg) {

            this.startDateFormat = moment(String(date)).format('DD/MM/YYYY');
            console.debug(" this.startDateFormat", this.startDateFormat, " ", msg);

        },
        goToMainView: function () {
            this.id = null;
            this.name = "";
            this.dateStart = "";
            this.dateEnd = "";
            this.visibility = "";
            this.image = null;
            this.html = "";
            this.$store.commit("resetPageCreatorData");
        },
        saveImage: function (e) {
            const fileObject = e.target.files[0]; // get first file
            const reader = new FileReader();
            reader.onload = (e) => {
                this.image = e.target.result;
            };
            reader.readAsDataURL(fileObject);
        },
        deleteImage: function () {
            this.image = null;
        },
        addPage: function () {
            if (!this.checkForm()) return;
            this.loading = true;
            this.apiQuery(APIs.addPage, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: JSON.stringify({
                    code: null,
                    headline: this.pageCreatorData.parentCategory.name,
                    html: this.pageCreatorData.parentCategory.html,
                    image: this.image,
                    publishDate: this.pageCreatorData.parentCategory.dateStart + "T00:00:00.000Z",
                    validityDate: this.pageCreatorData.parentCategory.dateEnd ? this.pageCreatorData.parentCategory.dateEnd + "T00:00:00.000Z" : "",
                    visibility: this.pageCreatorData.parentCategory.visibility.code,
                    type: this.type,
                    draft: this.checked ? "Y" : "N",
                    important: this.pageCreatorData.parentCategory.checkedImportant ? "Y" : "N",
                    user: this.pageCreatorData.parentCategory.optionsUser.code,
                    specialEmploymentCenter: this.pageCreatorData.parentCategory.specialEmployment.code,
                    codeSection: this.pageCreatorData.parentCategory.id,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        if (response.ok) {
                            this.$store.state.categoryLastUpdate = this.pageCreatorData.parentCategory.id;
                            this.goToMainView();
                            this.toast.success("Página creada correctamente.", { timeout: 3000 });
                            this.loading = false;
                        }
                    }
                    this.$emit("update");
                })
                .catch((e) => {
                    this.toast.error("Ha ocurrido un error inesperado, intentalo más tarde: " + e, { timeout: 3000 });
                    this.loading = false;
                });
        },
        updatePage: function () {
            if (!this.checkForm()) return;
            this.loading = true;

            this.apiQuery(APIs.updatePage, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: JSON.stringify({
                    code: this.pageCreatorData.parentCategory.id,
                    headline: this.pageCreatorData.parentCategory.name,
                    html: this.pageCreatorData.parentCategory.html,
                    image: this.image,
                    publishDate: this.pageCreatorData.parentCategory.dateStart + "T00:00:00.000Z",
                    validityDate: this.pageCreatorData.parentCategory.dateEnd ? this.pageCreatorData.parentCategory.dateEnd + "T00:00:00.000Z" : "",
                    visibility: this.pageCreatorData.parentCategory.visibility.code,
                    type: this.type,
                    draft: this.checked ? "Y" : "N",
                    important: this.pageCreatorData.parentCategory.checkedImportant ? "Y" : "N",
                    user: this.pageCreatorData.parentCategory.optionsUser.code,
                    codeSection: this.pageCreatorData.parentCategory.id,
                    specialEmploymentCenter: this.pageCreatorData.parentCategory.specialEmployment.code,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        this.$store.state.pageLastUpdate = this.id;
                        this.goToMainView();
                        this.toast.success("Página actualizada correctamente.", { timeout: 3000 });
                        this.loading = false;
                        this.$emit("update");
                    }
                })
                .catch((e) => {
                    this.toast.error("Ha ocurrido un error inesperado, intentalo más tarde: " + e, { timeout: 3000 });
                    this.loading = false;
                });
        },
        checkForm: function () {
            /**
          *    console.log(
                new Date(this.pageCreatorData.parentCategory.dateStart) > new Date(this.pageCreatorData.parentCategory.dateEnd),
                new Date(this.pageCreatorData.parentCategory.dateStart),
                new Date(this.pageCreatorData.parentCategory.dateEnd)
            );
            */
            if (
                !this.pageCreatorData.parentCategory.name ||
                !this.pageCreatorData.parentCategory.dateStart ||
                !this.pageCreatorData.parentCategory.visibility ||
                !this.pageCreatorData.parentCategory.html ||
                !this.pageCreatorData.parentCategory.optionsUser
            ) {
                this.toast.error("Debes rellenar todos los campos obligatorios.");
                return false;
            } else if (new Date(this.pageCreatorData.parentCategory.dateStart) >= new Date(this.pageCreatorData.parentCategory.dateEnd)) {
                this.toast.error("La fecha de vencimiento debe ser posterior a la fecha de publicación.");
                return false;
            }
            return true;
        },
        stateChange: function (isDraft) {
            if (isDraft) {
                if (this.draft) {
                    this.published = false;
                } else {
                    this.published = true;
                }
            } else {
                if (this.published) {
                    this.draft = false;
                } else {
                    this.draft = true;
                }
            }
            this.checked = this.draft;
        },
        setFileConverter: function (event) {
            if (event.target.files && event.target.files[0]) {
                var fileReader = new FileReader();

                const self = this;

                fileReader.onload = function (fileLoadedEvent) {
                    self.fileConverterData = fileLoadedEvent.target.result;
                };

                fileReader.readAsDataURL(event.target.files[0]);
            }
        },
        copyBase64: function () {
            if (!this.fileConverterData) return;

            navigator.clipboard
                .writeText(this.fileConverterData)
                .then(() => {
                    this.toast.info("Texto copiado al portapapeles.", { timeout: 3000 });
                })
                .catch((e) => {
                    this.toast.error("Ha ocurrido un error: " + e, { timeout: 3000 });
                });
        },
    },
    computed: {
        pageCreatorData: function () {
            return this.$store.state.pageCreatorData;
        },
    },
    watch: {
        loading: function (newLoading) {
            this.$isLoading(newLoading);
        },
        "pageCreatorData.visible": function (newVisibility) {
            if (newVisibility) {
                if (this.pageCreatorData.id != null) {
                    this.id = this.pageCreatorData.parentCategory.id;
                    this.name = this.pageCreatorData.parentCategory.name;
                    this.dateStart = this.pageCreatorData.parentCategory.dateStart;
                    this.dateEnd = this.pageCreatorData.parentCategory.dateEnd;
                    this.visibility = this.pageCreatorData.parentCategory.visibility;
                    this.image = this.pageCreatorData.parentCategory.image;
                    this.html = this.pageCreatorData.parentCategory.html;
                    this.$refs["coverImage"].value = "";
                }
                if (document.querySelector(".ck-source-editing-button.ck-on")) {
                    document.querySelector(".ck-source-editing-button.ck-on").click();
                }
            }
        },
        "pageCreatorData.mode": function (newMode) {
            this.$refs["coverImage"].value = "";
            //this.image = null;

            if (newMode != "edit") return;
            try {
                this.image = this.pageCreatorData.parentCategory.image;
            } catch {
                console.log("Empty image");
            }
        },
    },
};
</script>

<style scoped>
.switchImportant {
    margin: 0.5em;
    transform: scale(1.5);
}

.check {
    width: 50px;
    height: 50px;
}

#formGroupButtons {
    float: right;
}

#modal-button-delete-img {
    min-height: 55px;
    margin-left: 0px;
    width: 100%;
}

input,
label {
    display: block;
    margin-bottom: 3px;
}

.formGroup {
    text-align: left;
    display: block;
    margin-top: 25px;
}

.form {
    margin-top: 15px;
}

h2 {
    font-weight: bold;
}

textarea {
    margin-top: 0px;
    width: 100%;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-color: #a6a6a6;
}

#name {
    width: 100%;
}

#coverImage {
    width: 100%;
}

#imageurl {
    max-width: 350px;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
}

.modal-button {
    display: inline;
    text-align: center;
    width: 150px;
    margin-left: 20px;
    margin-right: 0px;
}

input,
select {
    width: 100%;
}

#visibilityTreeSelect {
    padding-top: 5px;
    font-size: 1.25rem;
    height: 50px;
    font-size: 18px;
}

.status {
    width: auto;
    float: right;
}

.checkStatus {
    width: 50px;
    height: 50px;
}

.p-inputtext-lg-date {
    font-size: 16px;
    min-height: 50px;
}
</style>
<style>
.ck-editor__editable {
    min-height: 500px;
}
</style>
