import { APIs, REST_CODES } from "./constantes";
import { getCookie } from "../store/cookies";
import { Semaphore } from "@codevor/js-semaphore";

const refreshingTokenSemaphore = Semaphore();

export const apiQuery = async function (endpoint, headers) {
    let response;
    try {
        response = await fetch(endpoint, headers);
    } catch {
        response = {
            status: REST_CODES.UNAUTHORIZED,
        };
    }

    if (!headers.headers.Authorization) {
        return new Promise((resolve) => resolve(response));
    }

    if (response.status == REST_CODES.UNAUTHORIZED) {
        const self = this;
        await refreshingTokenSemaphore.acquire().then(async () => {
            try {
                headers.headers.Authorization = "Bearer " + this.$store.getters.getToken;
                response = await fetch(endpoint, headers);
            } catch {
                response = {
                    status: REST_CODES.UNAUTHORIZED,
                };
            }

            if (response.status == REST_CODES.UNAUTHORIZED) {
                try {
                    await refreshToken(self);
                } catch {
                    self.$store.commit("logout");
                    window.location.replace("/portalAdmin/login?status=expiredSession");
                }

                headers.headers.Authorization = "Bearer " + this.$store.getters.getToken;
                response = await fetch(endpoint, headers);
            }

            refreshingTokenSemaphore.release();
        });
    }

    //this.$store.commit("setToken", "");
    //window.location.replace("/portalAdmin/login?status=expiredSession");

    return new Promise((resolve) => resolve(response));
};

async function refreshToken(self) {
    const loginQuery = await fetch(APIs.refreshToken, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            accept: "*/*",
            Authorization: "Bearer " + self.$store.getters.getRefreshToken,
        },
        body: JSON.stringify({
            refreshToken: self.$store.getters.getRefreshToken,
        }),
    });
    const loginResult = await loginQuery.json();
    let result = loginResult;

    self.$store.commit("setToken", result.ticket);
    self.$store.commit("setRefreshToken", result.refreshToken);

    self.$store.commit("setPasswordChange", self.$store.getters.mustChangePassword === "yes");
    self.$store.commit("setBtrlList", self.$store.getters.getBtrlList);

    self.$store.commit("setUserData", self.$store.getters.getUserData);

    self.$store.commit("setSapCode", JSON.parse(getCookie("sapCode")));
    return;
}
