<template>
    <div id="MenuLateralComponent" class="MenuLateral" v-show="!smallDevice || menuVisibility">
        <a class="closeButton" id="btnClose" @click="CloseMenu"></a>
        <ul :class="{ smallDevice: smallDevice }">
            <li
                v-for="m in filteredMenuData"
                :key="m.idMenu"
                :id="m.idMenu"
                v-show="smallDevice || m.titulo != 'Noticias'"
                @click.self="SeleccionarMenu(m)"
                class="liParent"
                :class="{
                    smallDevice: smallDevice,
                    seleccionado: menuSeleccionado === m.idMenu || (typeof menuSeleccionado == 'string' && menuSeleccionado.includes(m.idMenu + '>')),
                }"
            >
                <!-- GetClass(m), -->
                <i class="customIcons" :class="m.icono" @click="SeleccionarMenu(m)"></i>
                <!-- <font-awesome-icon :icon="m.icono" size="2x" class="fa-fw customIcons" /> -->
                <span v-if="m.notificaciones" @click="SeleccionarMenu(m)" class="badge">{{ m.notificaciones }} a</span>
                <a :class="{ smallDevice: smallDevice }" @click="SeleccionarMenu(m)">{{ m.titulo }}</a>
                <ul v-if="m.children != undefined && m.children.length > 0" :class="{ smallDevice }">
                    <li
                        v-for="mc in m.children"
                        :key="mc.idMenu"
                        :id="mc.idMenu"
                        v-show="MostrarElemento(mc)"
                        @click="SeleccionarSubMenu(mc)"
                        class="liChild"
                        :class="{ smallDevice }"
                    >
                        <!--ss  <i :class="mc.icono" class="customIcons fa-2x"></i>-->
                        <!-- <font-awesome-icon :icon="mc.icono" size="2x" class="fa-fw customIcons" /> -->
                        <template v-if="mc.notificaciones">
                            <span class="badge">{{ mc.notificaciones }}</span>
                        </template>
                        <a :class="{ smallDevice: smallDevice }">{{ mc.titulo }}</a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import { APIs } from "../common/constantes.js";

export default {
    name: "MenuLateral",
    props: ["visible"],
    data() {
        return {
            menuSeleccionado: 1,
            smallDevice: false,
            currentUser: {
                Id: 1,
                EsSupervisor: false,
            },
            datosMenu: [
                {
                    idMenu: 1,
                    icono: "fa-solid fa-spa",
                    notificaciones: null,
                    children: [],
                    titulo: "Gestión club del empleado/a",
                    usuarioSupervisor: false,
                    type: "menu",
                },
                {
                    idMenu: 2,
                    icono: "fa-solid fa-spa",
                    notificaciones: null,
                    children: [],
                    titulo: "Previsualización club del empleado/a",
                    usuarioSupervisor: false,
                    type: "menu",
                },
                {
                    idMenu: 4,
                    icono: "fa-solid fa-spa",
                    notificaciones: null,
                    children: [],
                    titulo: "Gestión información de interés",
                    usuarioSupervisor: false,
                    type: "menu",
                },
                {
                    idMenu: 3,
                    icono: "fa-solid fa-spa",
                    notificaciones: null,
                    children: [],
                    titulo: "Previsualización información de interés",
                    usuarioSupervisor: false,
                    type: "menu",
                },
                {
                    idMenu: 8,
                    icono: "fa-solid fa-key",
                    notificaciones: null,
                    children: [],
                    titulo: "Cambiar contraseña",
                    usuarioSupervisor: false,
                    type: "changePassword",
                },
                {
                    idMenu: 10,
                    icono: "fa-solid fa-power-off",
                    notificaciones: null,
                    children: [],
                    titulo: "Cerrar sesión",
                    usuarioSupervisor: false,
                    type: "logout",
                },
            ],
        };
    },
    computed: {
        user: function () {
            return this.$store.getters.getUser;
        },
        menuVisibility: function () {
            return this.$store.getters.getMenuVisibility;
        },
        authToken: function () {
            return this.$store.getters.getToken;
        },
        filteredMenuData: function () {
            return this.datosMenu.filter((row) => !row.usuarioSupervisor || row.usuarioSupervisor == this.currentUser.EsSupervisor);
        },
        responsableNotifications: function () {
            return this.$store.getters.getResponsableNotifications;
        },
    },
    watch: {
        loading: function (newLoading) {
            this.$isLoading(newLoading);
        },
        responsableNotifications: function (newValue) {
            this.datosMenu[4].notificaciones = newValue;
        },
    },
    methods: {
        GetClass: (m) => {
            if (m.children != undefined && m.children.length > 0) return "";
            return "liParent";
        },
        CloseMenu: function () {
            this.$emit("visibleChild", !this.visible);
        },
        SeleccionarMenu: function (Menu) {
            let idMenu = Menu.idMenu;
            /**if (this.datosMenu[idMenu - 1].children.length > 0) {
                    let elementos = document.getElementsByTagName("li");
                    for (let i = 0; i < elementos.length; i++) {
                        if (elementos[i].id == idMenu) {
                            if (
                                elementos[i].className.indexOf(
                                    "seleccionado"
                                ) === -1
                            )
                                elementos[i].className += "seleccionado";
                        } else {
                            elementos[i].className = elementos[
                                i
                            ].className.replace(" seleccionado", "");
                        }
                    }
                    return;
                }*/
            this.$store.commit("setMenuVisibility", false);
            if (Menu.type == "logout") {
                this.$store.commit("logout");
                window.location.reload();
                return;
            } else if (Menu.type == "changePassword") {
                this.$router.push({ name: "passwordChange" });
                return;
            } else if (Menu.type == "href") {
                window.open(Menu.url, "_blank");
                return;
            } else if (Menu.type == "none") {
                return;
            }

            this.menuSeleccionado = idMenu;

            this.$store.commit("setVisibleMenu", idMenu);
            this.$emit("menuChange");
        },
        SeleccionarSubMenu: function (submenu) {
            if (submenu.type == "submenu") {
                this.$store.commit("setVisibleMenu", submenu.idMenu);
                this.$emit("menuChange");
            } else if (submenu.type == "menu") {
                this.$store.commit("setVisibleMenu", submenu.idMenu);
                this.$emit("menuChange");
            }

            this.menuSeleccionado = submenu.idMenu;
        },
        MostrarElemento: function (menu) {
            //let esunsupervisor=this.currentUser.EsSupervisor;

            let mostrar = true;
            // se previene el fallo de la primera entrada.
            if (this.currentUser.Id == "undefined" || this.currentUser.Id == null) {
                if (menu.usuarioSupervisor) {
                    mostrar = false;
                }
            } else {
                if (!this.currentUser.EsSupervisor || this.currentUser.EsSupervisorisor == "undefined" || this.currentUser.EsSupervisor == null) {
                    this.currentUser.EsSupervisor = false;
                    if (menu.usuarioSupervisor && !this.currentUser.EsSupervisor) {
                        // si el menú requiere un supervisor y no lo somos no lo mostramos.
                        mostrar = false;
                    } else {
                        //if(menu.usurioServicio && !esunsupervisor) {
                        //    mostrar = false;
                        //}
                    }
                } else {
                    if (menu.usuarioSupervisor && !this.currentUser.EsSupervisor) {
                        // si el menú requiere un supervisor y no lo somos no lo mostramos.
                        mostrar = false;
                    } else {
                        //if(menu.usurioServicio && !esunsupervisor) {
                        //    mostrar = false;
                        //}
                    }
                }
            }
            //console.log("user :"+ this.currentUser.Id + "  Menu: "+ menu.titulo + " : "+ mostrar);
            return mostrar;
        },
        resizeMenu: function () {
            this.smallDevice = document.documentElement.clientWidth < 600;
        },
        setWellnesAreaSubmenus: function () {
            this.apiQuery(APIs.getSections, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.authToken,
                },
                body: JSON.stringify({
                    codeNvCategory: 1,
                    visibility: this.codHandicapped,
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    result.forEach((card) => {
                        this.datosMenu[7].children.push({
                            idMenu: "9>" + card.code,
                            icono: "",
                            notificacion: null,
                            titulo: card.name,
                            type: "submenu",
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.SeleccionarMenu(this.menuSeleccionado);
        this.resizeMenu();
        //  this.setWellnesAreaSubmenus();
    },
    created() {
        window.addEventListener("resize", this.resizeMenu);
    },
    unmounted() {
        window.removeEventListener("resize", this.resizeMenu);
    },
};
</script>

<style scoped>
.MenuLateral {
    width: 100%;
    height: 100%;
    background-color: #1e0072;
    position: relative;
}

ul {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline;
    background-color: #1e0072;
}
ul.smallDevice {
    top: 100px;
    height: 100vh;
    position: fixed;
}
ul li {
    display: inline-block;
    padding: 10px;
    margin: 0;
    position: relative;
}
.liParent {
    overflow-wrap: break-word;
    transition: all 0.2s ease-in-out;
}
.liParent.smallDevice {
    width: 100%;
}
.liParent:hover {
    background-color: #0070ba;
    cursor: pointer;
}
ul li svg {
    vertical-align: middle;
}
ul li a {
    color: white;
}
a {
    text-decoration: none;
}
a.smallDevice {
    font-size: 20px;
}
.seleccionado {
    background-color: #f49600;
}
.closeButton {
    cursor: pointer;
    color: #fff;
}
.badge {
    position: absolute;
    left: 28px;
    top: 2px;
    padding: 2px 6px;
    border-radius: 50%;
    background: red;
    color: white;
}
.customIcons {
    color: white;
    margin-right: 20px;
}

ul li ul:not(.smallDevice) {
    display: none;
}

ul li:hover ul:not(.smallDevice) {
    display: block;
    position: absolute;
    left: 0;
    top: 39px;
    width: auto;
}

ul li ul li:not(.smallDevice) {
    width: 100%;
    -webkit-box-shadow: 5px 5px 12px -1px gray;
    box-shadow: 5px 5px 12px -1px gray;
}

.liChild {
    width: 100%;
    padding: 10px 35px 10px 10px;
    transition: all 0.2s ease-in-out;
    background-color: #1e0072;
}

.liChild:hover {
    background-color: #0070ba;
}
@media (min-width: 600px) {
    ul li:last-child {
        width: auto;
        right: 10px;
        float: right;
    }
}
</style>
