<template>
    <div class="footer">
        <div class="section">
            <!-- Asistencia: <i class="fa-solid fa-phone"></i> 555 67 23 81 |
            <i class="fa-solid fa-envelope"></i> support@example.es -->
            Grupo SIFU: <i class="fa-solid fa-phone"></i> 900 447 447
        </div>
    </div>
</template>
<style scoped>
.footer {
    width: 100%;
    background-color: #1e0072;
    padding: 15px;
    display: block;
    bottom: 0;
}

.section {
    color: white;
}
</style>
<script>
export default {
    name: "Footer-Component",
};
</script>
